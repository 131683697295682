.authOptions {
  margin: 1rem 0;
}

.optionText {
  color: var(--dark-blue);
  font-size: 0.875rem;
  margin-right: 2rem;
  text-decoration: none;
  outline-color: var(--dark-blue);
}

.optionText:nth-child(2) {
  text-align: right;
  margin-right: 0;
}
